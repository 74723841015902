/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import parse from "html-react-parser";
import languages from "../Data/Languages.json";
import Service from "../Utilities/Service";
import SurveyType from "../Data/SurveyType.json";
import Theme from "../Data/Theme.json";
import SurveyInaccessibleType from "../Data/SurveyInaccessibleType.json";
import {
	updateResponse,
	createPopupResponse,
	createAnonymousResponse,
	showSurveyInaccessiblePage,
	setCssClasses,
	animate,
	getModifiedSurveySchema,
} from "./Helper";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import "./index.css";
import SurveyHeader from "./SurveyHeader";
import "survey-core/survey.i18n.js";
import NoInternetIcon from "../Images/NoInternetIcon";
import handleServiceError from "../Utilities/handleServiceError";
import FooterContent from "../components/FooterContent";
import { PopupEventTypes } from "../Data/PopupEventTypes";
import usePopupSignals from "../Utilities/usePopupSignals";

const getBackgroundColor = () => {
	const backgroundElement = document.getElementById("survey-page-wrapper");
	return window.getComputedStyle(backgroundElement).backgroundColor;
};

const service = new Service().proxy;
function SurveyComponent(props) {
	const { signalSurveyCompleted, signalReady, signalSurveyPageChanged, attachSurveyContentRef } = usePopupSignals({
		enablePopup: props.enablePopup,
		instanceId: props.instanceId,
		getBackgroundColor: getBackgroundColor,
	});

	//the template is placed differently, depending on if it is a universal or personal link
	const template = props.respondent.template ?? props.respondent.campaign?.template;

	const survey = useMemo(
		() => new Model(getModifiedSurveySchema({ schema: props.surveySchema.json, template: template })),
		[props.surveySchema.json, template]
	);

	const [stylingReady, setStylingReady] = useState(false);
	const popupDataRef = useRef(null);

	const appInsights = useAppInsightsContext();
	const footerRef = useRef(null);
	const responseRef = useRef(props.respondent.response);
	const doAnimationRef = useRef(true); //is only used if props.surveyDoAnimation is true.

	//used for universal link campaigns, after posting the response. If needing to edit it, this ref should hold the new id
	const newAnonymousPublicGuid = useRef('');

	//updates response with the newest survey.js answers
	const saveAnswers = useCallback(async () => {
		var closedInformation = await service.responseClosed();
		updateResponse(closedInformation, survey, props.surveyType, responseRef.current);
	}, [props.surveyType, survey]);

	const saveResponse = useCallback(async () => {
		try {
			await saveAnswers();
			var res = await service.editResponse(responseRef.current);
		} catch (e) {
			handleServiceError(e, props.setStatus, props.setErrorDetails);
		}
		responseRef.current = res;
	}, [props.setStatus, saveAnswers, props.setErrorDetails]);

	const saveFinalResponse = useCallback(async () => {
		try {
			if (props.enablePopup) {
				//signal survey completed right away, for letting the popup now, and the closeDelay to be correct.
				signalSurveyCompleted();
				await service.addPopupResponse(createPopupResponse(survey), popupDataRef.current.campaignMember);
				return;
			}
			if (props.surveyType === SurveyType.UNIVERSAL) {
				if(!newAnonymousPublicGuid.current){
					responseRef.current = createAnonymousResponse(survey);
					newAnonymousPublicGuid.current = await service.addRespondentAndResponse(responseRef.current);
				} else {
					await service.updatePublicConsentOnAnonymousCampaignMember(responseRef.current, newAnonymousPublicGuid.current);
				}
				return;
			}

			await saveAnswers();
			await service.addFinalResponse(responseRef.current);
		} catch (e) {
			handleServiceError(e, props.setStatus, props.setErrorDetails);
		}
	}, [
		props.surveyType,
		props.setStatus,
		props.setErrorDetails,
		props.enablePopup,
		saveAnswers,
		survey,
		signalSurveyCompleted,
	]);
	
	async function hideFooterAndShowUnsubscribePage() {
		try {
			await service.unsubscribe();
			showSurveyInaccessiblePage(survey, SurveyInaccessibleType.UNSUBSCRIBED);
			footerRef.current.style.display = "None";
			return true;
		} catch (e) {
			handleServiceError(e, props.setStatus, props.setErrorDetails);
		}
	}

	useEffect(() => {
		const goToUnsubscribePage = props.unsubscribedInitially && survey;
		if (goToUnsubscribePage) {
			showSurveyInaccessiblePage(survey, SurveyInaccessibleType.UNSUBSCRIBED);
		}
	}, [props.unsubscribedInitially, survey]);

	const updateFinishButtonStyling = useCallback(() => {
		const finishButtonElement = document.getElementsByClassName("sd-navigation__complete-btn")?.[0];
		if (finishButtonElement) {
			const isOnline = navigator.onLine;
			const buttonWrapperClassList = finishButtonElement.parentElement.classList;
			const buttonClassList = finishButtonElement.classList;
			if (!isOnline) {
				buttonWrapperClassList?.add("disableButtonWrapper");
				buttonClassList?.add("disableButton");
			} else {
				buttonWrapperClassList?.remove("disableButtonWrapper");
				buttonClassList?.remove("disableButton");
			}

			finishButtonElement.insertAdjacentElement("afterend", document.getElementById("noInternetIcon"));
		}
	}, []);

	useEffect(() => {
		window.addEventListener("online", updateFinishButtonStyling);
		window.addEventListener("offline", updateFinishButtonStyling);

		return () => {
			window.removeEventListener("online", updateFinishButtonStyling);
			window.removeEventListener("offline", updateFinishButtonStyling);
		};
	}, [updateFinishButtonStyling]);

	useEffect(() => {
		const primaryColor = template?.colorScheme;
		if (primaryColor) {
			document.querySelector(":root").style.setProperty("--primary", primaryColor);
		}

		async function fixFlickeringAndApplyThemeCssFiles() {
			const theme = props.surveyTheme;
			if (!theme || theme === Theme.defaultV2) {
				await import("survey-core/defaultV2.css");
			} else {
				switch (theme) {
					case Theme.bootstrap:
						await import("bootstrap/dist/css/bootstrap.css");
						await import("survey-core/survey.css");
						break;
					case Theme.modern:
						await import("survey-core/modern.css");
						break;
					default:
						await import("survey-core/survey.css");
				}
			}
			setStylingReady(true);
		}
		
		fixFlickeringAndApplyThemeCssFiles();
		if (responseRef.current && responseRef.current.closed) {
			showSurveyInaccessiblePage(survey, SurveyInaccessibleType.ALREADY_ANSWERED);
			return;
		}
		if (!props.acceptingResponses) {
			showSurveyInaccessiblePage(survey, SurveyInaccessibleType.CAMPAIGN_CLOSED);
			return;
		}
		
		const existingSurveyAnswers = props.respondent?.response?.surveyAnswers;
		if (existingSurveyAnswers) {
			props.respondent?.response?.surveyAnswers.forEach(surveyAnswer => {
				const name = surveyAnswer["name"];
				const value = surveyAnswer["value"];
				const hasNameAndValue = name && value;
				if (hasNameAndValue){
					survey.setValue(name, value);
				}
			})
		}
		const shouldUpdateSurveyFormWithNewRatingFromProps = typeof props.rating === "number" && props.respondent?.response;
		if (shouldUpdateSurveyFormWithNewRatingFromProps) {
			const newRating = props.rating;
			const currentRating = survey.getValue("rating");

			survey.setValue("rating", props.rating);

			const newRatingIsDifferentFromCurrentRating = newRating !== currentRating;
			const partialResponseIsAvailable = props.allowEditResponse && !props.template.forceSingleSubmit;
			if (newRatingIsDifferentFromCurrentRating && partialResponseIsAvailable) {
				const updatedResponse = { ...responseRef.current, rating: newRating };
				responseRef.current = updatedResponse;
				saveResponse();
			}
		}
		const hideAnonymousQuestion = props.surveyType === SurveyType.UNIVERSAL;
		if (hideAnonymousQuestion) {
			const anonymous = survey.getQuestionByName("anonymous");
			if (anonymous) {
				anonymous.visible = false;
			}
		}
		if (template.consentForPublicUse) {
			survey.pages.forEach((element) => {
				element.questions.forEach((q) => {
					if (q.name === "consent") {
						q.visible = true;
						if (template.consentIsOptOut) q.value = true;
					}
				});
			});
		}
	}, [
		props.surveyTheme,
		props.surveyType,
		props.rating,
		props.acceptingResponses,
		template.consentForPublicUse,
		template.consentIsOptOut,
		template?.colorScheme,
		props.respondent,
		survey,
		props.allowEditResponse,
		saveResponse,
		props.template.forceSingleSubmit,
	]);

	useEffect(() => {
		appInsights.trackPageView({ name: "SurveyComponent.js" });
	}, [appInsights]);

	useEffect(() => {
		survey.focusFirstQuestionAutomatic = false;

		survey.onAfterRenderPage.add(() => {
			updateFinishButtonStyling();
		});

		survey.onCurrentPageChanged.add(async (sender, options) => {
			if (props.surveyDoAnimation) animate("fadeIn", 1000);
			if (props.surveyType === SurveyType.UNIVERSAL || template.forceSingleSubmit || !props.allowEditResponse) return;
			await saveResponse();
			appInsights.trackEvent({
				name: "Changed Page",
				properties: { ...responseRef.current, pageNo: sender.currentPageNo },
			});
		});

		survey.onCurrentPageChanging.add(function (sender, options) {
			if (props.enablePopup) {
				signalSurveyPageChanged();
				return;
			}
			if (
				!doAnimationRef.current ||
				!props.surveyDoAnimation ||
				(responseRef.current && responseRef.current.closed) ||
				!props.acceptingResponses
			)
				return;
			options.allowChanging = false;
			setTimeout(function () {
				doAnimationRef.current = false;
				sender.currentPage = options.newCurrentPage;
				doAnimationRef.current = true;
			}, 1000);
			animate("fadeOut", 1000);
		});
		survey.onComplete.add(async (sender, options) => {
			await saveFinalResponse();
			appInsights.trackEvent({
				name: "Finished Survey",
				properties: { ...responseRef.current, pageNo: sender.currentPageNo },
			});

			if (props.template.autoReload) {
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			}
		});

		survey.onUpdateQuestionCssClasses.add((survey, options) => {
			setCssClasses(options);
		});
	}, [
		survey,
		props,
		template,
		appInsights,
		updateFinishButtonStyling,
		saveFinalResponse,
		saveResponse,
		signalSurveyPageChanged,
	]);

	useEffect(() => {
		if (!stylingReady || !props.enablePopup) {
			return;
		}
		const receiveData = (event) => {
			if (
				event.data.eventType === PopupEventTypes.SET_SURVEY_PARAMETERS &&
				event.data.instanceId === props.instanceId
			) {
				popupDataRef.current = event.data;
			}
		};
		window.addEventListener("message", receiveData, false);

		signalReady();
		return () => {
			window.removeEventListener("message", receiveData);
		};
	}, [props.instanceId, props.enablePopup, stylingReady, signalReady]);

	const language = template.language.name;

	const fallbackFontFamilies = "Arial, Helvetica, Work Sans";
	const fontFamily = template.fontFamily ? `${template.fontFamily}, ${fallbackFontFamilies}` : fallbackFontFamilies;

	const organizationName = props.respondent.organizationName || props.respondent.companyName;

	return (
		<div>
			{stylingReady && (
				<div id='survey-page-wrapper' className='pageWrapper' style={{ fontFamily: fontFamily }}>
					<div className='surveyWrapper' ref={attachSurveyContentRef}>
						{!props.enablePopup && <SurveyHeader template={template} />}
						<Survey model={survey} key={survey.locale} />
						{!props.enablePopup && (
							<div
								className='info-text'
								ref={footerRef}
								style={{
									color: "#a0a0a0",
									fontSize: "13px",
									textAlign: "center",
									margin: "10px 0 10px 0",
								}}
							>
								<FooterContent htmlString={props.template.footerContent} />
								&nbsp;
								<span>
									{organizationName ? (
										<>
											{parse(languages.RUN_BY[language])}&nbsp;<span>{organizationName}.</span>
										</>
									) : (
										<>{parse(languages.RUN_BY_COMPANY[language])}.</>
									)}
								</span>
								{props.surveyType !== SurveyType.UNIVERSAL && template.enableUnsubscribe && (
									<>
										&nbsp;
										<a
											href={"#"}
											onClick={() => {
												hideFooterAndShowUnsubscribePage();
											}}
											style={{ color: "#b5b5b5" }}
										>
											<span>{languages.UNSUBSCRIBE[language]}</span>
										</a>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			)}
			{/* Element below are hidden, used for injection in other components */}
			<NoInternetIcon />
		</div>
	);
}
export default SurveyComponent;
